<template>
    <button
        :class="classList"
        :style="buttonStyle"
        class="pwButton"
        :type="type"
        :disabled="disabled"
        @click="onClick"
    >
        <template v-if="props.iconPosition === 'left' || props.iconPosition === 'both'">
            <pw-icon :name="icon" :size="size" :color="color"/>
        </template>
        <span class="label">
            <slot>
                {{ label }}
            </slot>
        </span>
        <template v-if="props.iconPosition === 'right' || props.iconPosition === 'both'">
            <pw-icon :name="icon" :size="size" :color="color"/>
        </template>
    </button>
</template>
<script setup>
import {ref, watch, computed} from 'vue';
import PwIcon from '../../pwComponents/PwIcon/PwIcon.vue';

const emit = defineEmits(['on-click', 'click'])
const props = defineProps({
    width: {
        type: Number,
        default: 343,
    },
    height: {
        type: Number,
        default: 56,
    },
    theme: {
        type: String,
        default: 'isPrimary',
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: '',
    },
    icon: {
        type: String,
        default: ''
    },
    size: {
        type: Number,
        default: 18
    },
    type: {
        type: String,
        default: 'button',
        validator: (value) => ['button', 'submit', 'reset'].includes(value.toLowerCase()),
    },
    iconPosition: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right', 'both'].includes(value.toLowerCase())
    }
})

const buttonStyle = computed(() => {
    let style = {
        display: 'flex',
        alignItems: 'center'
    };

    if (props.iconPosition === 'both') {
        style.justifyContent = 'space-between';
    } else if (props.iconPosition === 'right') {
    }

    style.width = props.fullWidth ? '100%' : `${props.width}px`;
    style.height = `${props.height}px`;

    return style;
});

const setClasses = () => {
    const classes = ['pwButton']
    classes.push(`pwButton__${props.theme}`)
    return classes
}

const classes = ref(setClasses())

const onClick = () => {
    emit('click')
    emit('on-click')
}

watch(props, () => {
    classes.value = setClasses()
})

const classList = computed(() => classes.value.join(' '))
</script>
<style lang="scss" scoped>
@import './PwButtonStyle.scss';
</style>
