<template>
    <svg
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M7.125 1.0625C7.125 0.757812 7.35938 0.5 7.6875 0.5H11.4141C11.7422 0.5 11.9766 0.757812 11.9766 1.0625V4.8125C11.9766 5.14062 11.7422 5.375 11.4141 5.375C11.1094 5.375 10.8516 5.14062 10.8516 4.8125V2.42188L5.625 7.64844C5.41406 7.88281 5.0625 7.88281 4.85156 7.64844C4.61719 7.4375 4.61719 7.08594 4.85156 6.85156L10.0781 1.625H7.6875C7.35938 1.625 7.125 1.39062 7.125 1.0625ZM1.6875 1.25H4.6875C4.99219 1.25 5.25 1.50781 5.25 1.8125C5.25 2.14062 4.99219 2.375 4.6875 2.375H1.6875C1.35938 2.375 1.125 2.63281 1.125 2.9375V10.8125C1.125 11.1406 1.35938 11.375 1.6875 11.375H9.5625C9.86719 11.375 10.125 11.1406 10.125 10.8125V7.8125C10.125 7.50781 10.3594 7.25 10.6875 7.25C10.9922 7.25 11.25 7.50781 11.25 7.8125V10.8125C11.25 11.75 10.4766 12.5 9.5625 12.5H1.6875C0.75 12.5 0 11.75 0 10.8125V2.9375C0 2.02344 0.75 1.25 1.6875 1.25Z"
            :fill="color"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-link',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>