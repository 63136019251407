import {defineStore} from 'pinia';
import {UserInfo} from "../core/models/index.js";
import UserInfoRepository from "../core/repository/UserInfoRepository.js";
import HttpServerFactory from "../core/services/HttpServerFactory.js";
import {AuthService} from "../core/services/AuthService.js";

export const useAuthenticationStore = defineStore('authenticationStore', {
    state: () => ({
        userInfo: new UserInfo(),
        hasError: false,
    }),

    getters: {
        dataLoaded: (state) => {
            return state.userInfo?.id > 0
        }
    },

    actions: {
        async loadInfo() {
            try {
                const repository = new UserInfoRepository(HttpServerFactory.createBussolaApiHttpServer())
                this.userInfo = await repository.get(AuthService.getInstance().userId)
            } catch (e) {
                this.hasError = true
            }
        }
    }

})