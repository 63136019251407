<template>
    <svg
        viewBox="0 0 25 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M6.76562 17.375C7.375 16.9062 8.17188 16.8125 8.875 17.0469C10.0938 17.5156 11.5 17.75 13 17.75C18.8125 17.75 22.75 14 22.75 10.25C22.75 6.54688 18.8125 2.75 13 2.75C7.14062 2.75 3.25 6.54688 3.25 10.25C3.25 11.75 3.8125 13.2031 4.89062 14.4688C5.3125 14.8906 5.5 15.5 5.45312 16.1094C5.40625 16.9531 5.17188 17.75 4.9375 18.4531C5.73438 18.0781 6.39062 17.6562 6.76562 17.375ZM1.98438 19.25C2.07812 19.1562 2.125 19.0156 2.21875 18.875C2.6875 18.125 3.10938 17.0938 3.20312 15.9219C1.79688 14.3281 1 12.4062 1 10.25C1 4.90625 6.34375 0.5 13 0.5C19.6094 0.5 25 4.90625 25 10.25C25 15.6406 19.6094 20 13 20C11.2188 20 9.57812 19.7188 8.07812 19.2031C7.5625 19.5781 6.625 20.1406 5.54688 20.6094C4.84375 20.9375 4.04688 21.2188 3.20312 21.3594C3.15625 21.3594 3.10938 21.4062 3.10938 21.4062C2.875 21.4531 2.6875 21.4531 2.45312 21.5C2.21875 21.5 1.98438 21.5469 1.70312 21.5469C1.42188 21.5469 1.14062 21.3594 1.04688 21.0781C0.90625 20.7969 1 20.4688 1.1875 20.2344C1.375 20.0469 1.5625 19.8594 1.70312 19.625C1.79688 19.4844 1.89062 19.3906 1.9375 19.2969C1.9375 19.2969 1.9375 19.2969 1.9375 19.25H1.98438Z"
            fill="#5E58EA"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-report',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>