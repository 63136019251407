<template>
    <pw-modal
        v-model="visibility"
        size="xs"
        title="Instale o aplicativo!"
        subTitle="Acesse o app rapidamente através do ícone na tela inicial do seu dispositivo."
        persistent
    >
        <div class="modalConfirmation">
            <div class="modalConfirmation__buttons" style="flex-direction: column;">
                <pw-button
                    label="Instalar agora!"
                    icon="download"
                    :size="24"
                    iconPosition="left"
                    @click="$emit('accepted', true)"
                    full-width
                />
                <pw-button
                    label="Lembre-me mais tarde"
                    theme="isSecondary"
                    iconPosition="left"
                    @click="$emit('accepted', false)"
                    full-width
                />
            </div>
        </div>
    </pw-modal>
</template>
<script setup>
import {computed} from 'vue'
import PwButton from '~/pwComponents/PwButton/PwButton.vue';
import PwModal from '~/pwComponents/PwModal/PwModal.vue';

const emit = defineEmits(['update:opened', 'accepted']);

const props = defineProps({
    opened: {
        type: Boolean,
        default: false
    },
})

const visibility = computed({
    get() {
        return props.opened
    },
    set(value) {
        emit('update:opened', value)
    }
})
</script>
<style lang="scss" scoped>
@import './ModalConfirmationStyle.scss';
</style>