<template>
    <svg
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M14.7812 1.21875C15.0625 1.53125 15.0625 2 14.7812 2.28125L6.53125 10.5312C6.21875 10.8438 5.75 10.8438 5.46875 10.5312L1.21875 6.28125C0.90625 6 0.90625 5.53125 1.21875 5.25C1.5 4.9375 1.96875 4.9375 2.25 5.25L5.96875 8.96875L13.7188 1.21875C14 0.9375 14.4688 0.9375 14.75 1.21875H14.7812Z"
            :fill="color"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-check',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>