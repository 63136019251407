<template>
    <svg
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M0.828125 10.8438C0.59375 10.6094 0.5 10.3281 0.5 10C0.5 9.71875 0.59375 9.4375 0.828125 9.20312L9.07812 1.32812C9.54688 0.90625 10.25 0.90625 10.6719 1.375C11.0938 1.79688 11.0938 2.54688 10.625 2.96875L4.39062 8.875H20.375C20.9844 8.875 21.5 9.39062 21.5 10C21.5 10.6562 20.9844 11.125 20.375 11.125H4.39062L10.625 17.0781C11.0938 17.5 11.0938 18.2031 10.6719 18.6719C10.25 19.1406 9.54688 19.1406 9.07812 18.7188L0.828125 10.8438Z"
            fill="#E1E6FE"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-left-arrow',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>