<template>
    <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M7.8125 4.49219L3.17188 12.1094C3.125 12.1797 3.125 12.2266 3.125 12.2969C3.125 12.4844 3.26562 12.625 3.45312 12.625H12.5234C12.7109 12.625 12.875 12.4844 12.875 12.2969C12.875 12.2266 12.8516 12.1797 12.8047 12.1094L8.16406 4.49219C8.14062 4.42188 8.07031 4.375 8 4.375C7.90625 4.375 7.85938 4.42188 7.8125 4.49219ZM6.85156 3.90625C7.08594 3.50781 7.53125 3.25 8 3.25C8.44531 3.25 8.89062 3.50781 9.125 3.90625L13.7656 11.5234C13.9062 11.7578 14 12.0156 14 12.2969C14 13.0938 13.3438 13.75 12.5234 13.75H3.45312C2.65625 13.75 2 13.0938 2 12.2969C2 12.0156 2.07031 11.7578 2.21094 11.5234L6.85156 3.90625ZM8.75 11.125C8.75 11.5469 8.39844 11.875 8 11.875C7.57812 11.875 7.25 11.5469 7.25 11.125C7.25 10.7266 7.57812 10.375 8 10.375C8.39844 10.375 8.75 10.7266 8.75 11.125ZM8.5625 6.8125V9.0625C8.5625 9.39062 8.30469 9.625 8 9.625C7.67188 9.625 7.4375 9.39062 7.4375 9.0625V6.8125C7.4375 6.50781 7.67188 6.25 8 6.25C8.30469 6.25 8.5625 6.50781 8.5625 6.8125Z"
            :fill="color"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-warning',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>