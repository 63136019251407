<template>
    <teleport to="body">
        <transition name="fade">
            <div v-if="modelValue" class="pwModal">
                <div class="pwModal__container">
                    <div class="pwModal__container--back" aria-hidden="true" @click="closeModalTouch"></div>
                    <div class="pwModal__content" :class="modalStyle">
                        <span v-touch:swipe.bottom="closeModal"
                              v-if="!persistent"
                              class="pwModal__content--line">
                        </span>
                        <div class="pwModal__content--header">
                            <h3
                                v-if="title"
                                :class="[{'pwModal__content--header--titleBold': titleBold }]"
                            >
                                {{ title }}
                            </h3>
                            <pw-line v-if="titleSeparetor"/>
                            <p v-if="subTitle" v-html="subTitle"></p>
                        </div>
                        <slot></slot>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>
<script setup>
import {computed, watch} from 'vue'
import {useRouter} from 'vue-router';
import PwLine from '../PwLine/PwLine.vue';

const emit = defineEmits(['update:modelValue'])
const router = useRouter();

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    titleSeparetor: {
        type: Boolean,
        default: true,
    },
    titleBold: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: null,
    },
    subTitle: {
        type: String,
        default: null,
    },
    persistent: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'sm',
        validator: function (value) {
            return (
                ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
            )
        },
    },
})

const modalStyle = computed(() => {
    let styles = ''

    switch (props.size) {
        case 'xs':
            styles = 'xsmall'
            break
        case 'sm':
            styles = 'small'
            break
        case 'md':
            styles = 'medium'
            break

        case 'lg':
            styles = 'large'
            break

        case 'xl':
            styles = 'extra-large'
            break

        default:
            styles = 'large'
            break
    }

    return styles
})

watch(
    () => props.modelValue,
    (value) => {
        if (value) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, {
        immediate: true
    }
)

const closeModal = () => {
    emit("update:modelValue", false);
}

const closeModalTouch = () => {
    if (props.persistent) {
        return
    }
    closeModal()
}
</script>
<style lang="scss" scoped>
@import './PwModalStyle.scss';
</style>
  