import {computed, ref} from "vue";
import {useAuthenticationStore} from "../stores/authenticationStore.js";

export const useUserInfo = () => {
    const useUserInfoStore = useAuthenticationStore()
    const userInfo = computed(() => useUserInfoStore.userInfo)
    const userInfoLoaded = computed(() => useUserInfoStore.dataLoaded)
    const hasUserNotAuthorized = computed(() => useUserInfoStore.hasError)

    return {
        userInfo,
        userInfoLoaded,
        hasUserNotAuthorized,
    }
}