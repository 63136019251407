<template>
    <section class="activeOrganization">
        <figure>
            <img :src="organization" alt="organization">
        </figure>
        <div>
            <h1 class="activeOrganization__title">
                {{ userInfo.org_nome }}
            </h1>
        </div>
    </section>
</template>
<script setup>
import organizationPlaceholder from '../../../assets/img/organization.png'
import {useUserInfo} from "../../../composables/useUserInfo.js";
import {computed, ref} from "vue";

const {userInfo} = useUserInfo()

const organization = computed(() => {
    if (!userInfo?.value.org_avatar) {
        return organizationPlaceholder
    }
    return userInfo?.value.org_avatar
})

</script>
<style scoped lang="scss">
@import './ActiveOrganizationStyle.scss';
</style>