<template>
    <svg
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M0.5 1.125C0.5 0.515625 0.96875 0 1.625 0H20.375C20.9844 0 21.5 0.515625 21.5 1.125C21.5 1.78125 20.9844 2.25 20.375 2.25H1.625C0.96875 2.25 0.5 1.78125 0.5 1.125ZM0.5 8.625C0.5 8.01562 0.96875 7.5 1.625 7.5H20.375C20.9844 7.5 21.5 8.01562 21.5 8.625C21.5 9.28125 20.9844 9.75 20.375 9.75H1.625C0.96875 9.75 0.5 9.28125 0.5 8.625ZM21.5 16.125C21.5 16.7812 20.9844 17.25 20.375 17.25H1.625C0.96875 17.25 0.5 16.7812 0.5 16.125C0.5 15.5156 0.96875 15 1.625 15H20.375C20.9844 15 21.5 15.5156 21.5 16.125Z"
            fill="#E1E6FE"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-menu',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>