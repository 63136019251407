<template>
    <header class="pwHeader">
        <div class="pwHeader__brand">
            <button v-if="showLeftArrow && !showMenu" @click="onBack"
                    style="padding: 4px"
                    class="icon-button">
                <pw-icon name="left-arrow" :size="24"/>
            </button>
            <pw-icon v-else name="bussola" :size="32"/>
            <h1>{{ title }}</h1>
        </div>
        <div>
            <button v-if="showMenuButton || showMenu" @click="toggleMenu" class="icon-button">
                <pw-icon v-if="showMenuButton" name="menu" :size="24"/>
                <pw-icon v-else name="close" color="#fff" :size="24"/>
            </button>
        </div>
    </header>
    <transition name="menu-fade">
        <div v-if="showMenu" class="pwHeader__menu">
            <div class="pwHeader__menu--avatar">
                <img :src="avatar" @error="onLoadImageError" alt="avatar" class="user">
                <div>
                    <h2 class="title">
                        {{ userInfo.nome }}
                    </h2>
                    <a :href="accountUrl" target="_blank" class="subTitle" v-if="false">
                        Minha Conta Bússola
                        <pw-icon name="link" :size="14"/>
                    </a>
                </div>
            </div>
            <div class="pwHeader__menu--info">
                <active-organization/>
                <pw-line/>
                <OptionLink
                    label="Ajuda"
                    icon="interrogation"
                    link="/ajuda"
                    linkIcon="right-arrow"
                />
                <div class="pwHeader__menu--button">
                    <pw-button
                        full-width
                        theme="isSecondary"
                        label="Encerrar sessão"
                        icon="logoff"
                        iconPosition="left"
                        @click="logout"
                    />
                    <p> Versão: {{ appVersion }}</p>
                </div>
            </div>
        </div>
    </transition>
</template>
<script setup>
import {ref, watch, computed, onMounted} from 'vue';
import PwIcon from '../../pwComponents/PwIcon/PwIcon.vue';
import PwLine from '../../pwComponents/PwLine/PwLine.vue';
import PwButton from '../../pwComponents/PwButton/PwButton.vue'
import ActiveOrganization from '../../components/Organizations/ActiveOrganization/ActiveOrganization.vue'
import avatarPlaceholder from '../../assets/img/avatar.png'
import {useRoute, useRouter} from 'vue-router';
import {AuthService} from "~/core/services/AuthService.js";
import {useUserInfo} from "~/composables/useUserInfo.js";
import OptionLink from '~/components/Shared/OptionLink/OptionLink.vue'

const accountUrl = import.meta.env.VITE_APP_BUSSOLA_SOCIAL_ACCOUNT_URL

const {userInfo} = useUserInfo()
const showMenu = ref(false);
const route = useRoute();
const router = useRouter();
const title = ref('');
const appVersion = APP_VERSION

const routeText = computed(() => {
    return typeof route.name === 'string' ? route.name : '';
});
const showMenuButton = computed(() => {
    return !showMenu.value;
})
const showLeftArrow = computed(() => {
    return route.name !== 'schedule';
})
const avatar = computed(() => {
    if (!userInfo?.value.avatar) {
        return avatarPlaceholder;
    }
    return userInfo?.value.avatar
})

const onBack = () => {
    if (router.currentRoute.value.name === 'help') {
        router.replace({name: 'schedule'})
        return;
    }
    router.back()
}

const logout = () => {
    AuthService.getInstance().logout()
};

const toggleMenu = () => {
    showMenu.value = !showMenu.value;
    if (showMenu.value) {
        title.value = 'Menu';
        document.body.style.overflow = 'hidden'
    } else {
        updateRouteTitle()
        document.body.style.overflow = 'auto'
    }
};

const updateRouteTitle = () => {
    title.value = route.meta.hasOwnProperty('title') ? route.meta.title : '';
}

const onLoadImageError = () => {
    imageHasError.value = true;
}

watch(
    () => route.name,
    () => {
        showMenu.value = false;
        updateRouteTitle()
    },
    {
        immediate: true
    }
)
</script>
<style lang="scss" scoped>
@import './PwHeaderStyle.scss';
</style>