<template>
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <g clip-path="url(#clip0_35_2497)">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M25.6417 6.79043C25.7254 5.53553 26.813 4.61527 28.0261 4.68499C29.281 4.76865 30.2431 5.8144 30.1733 7.0693C30.0897 8.3242 29.0439 9.28629 27.789 9.21658C26.5341 9.13292 25.572 8.04533 25.6417 6.79043Z"
                  fill="#E1E6FE"/>
            <path
                d="M30.9821 10.3739C30.8148 9.92769 30.2989 9.69066 29.8527 9.85798C29.3925 10.0253 29.1694 10.5412 29.3368 10.9874C29.9921 12.7303 30.2989 14.5708 30.2291 16.4392C29.9782 24.122 23.6339 30.2153 15.9651 30.2153C15.8257 30.2153 15.6862 30.2153 15.5328 30.2153C11.7403 30.0898 8.21258 28.5002 5.60517 25.7255C2.99777 22.9508 1.63132 19.3255 1.75681 15.5329C2.02173 7.7107 8.603 1.53379 16.4531 1.77083C19.2278 1.85449 21.905 2.74687 24.1638 4.33641C24.359 4.47584 24.596 4.51767 24.8191 4.48979C25.0562 4.44796 25.2514 4.32247 25.3908 4.12726C25.5302 3.93205 25.5721 3.69501 25.5442 3.47192C25.5163 3.24883 25.3769 3.03968 25.1816 2.90024C22.63 1.10155 19.6322 0.111572 16.5089 2.54838e-05C7.68273 -0.264898 0.292756 6.66495 -5.41871e-05 15.4771C-0.139488 19.7438 1.39428 23.8153 4.32239 26.9386C7.25049 30.0619 11.2104 31.8467 15.4771 31.9861C15.6583 31.9861 15.8257 31.9861 16.0069 31.9861C20.0784 31.9861 23.9546 30.4523 26.9385 27.6636C30.0618 24.7355 31.8466 20.7756 31.986 16.509C32.0557 14.4035 31.7211 12.3399 30.9821 10.3739Z"
                fill="#E1E6FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M21.3613 17.0806L18.0009 24.9168C13.4554 24.8331 16.3835 19.2976 15.5748 16.4671C18.3077 15.2261 20.204 15.5747 21.3613 17.0667V17.0806Z"
                  fill="#E1E6FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M23.0204 13.1486L21.3611 17.0667C16.8574 15.7839 7.78029 17.6244 7.85001 13.1904L20.2178 10.1647C23.1598 9.57907 24.4287 9.76034 23.0204 13.1486Z"
                  fill="#E1E6FE"/>
        </g>
        <defs>
            <clipPath id="clip0_35_2497">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
<script>

export default {
    name: 'icon-bussola',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>