<template>
    <svg
        viewBox="0 0 11 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M5.18945 6.2168L1.20508 2.23242C0.912109 1.93945 0.912109 1.5 1.20508 1.23633L1.84961 0.5625C2.14258 0.298828 2.58203 0.298828 2.8457 0.5625L5.6582 3.4043L8.5 0.5625C8.76367 0.298828 9.20312 0.298828 9.49609 0.5625L10.1406 1.23633C10.4336 1.5 10.4336 1.93945 10.1406 2.23242L6.15625 6.2168C5.89258 6.48047 5.45312 6.48047 5.18945 6.2168Z"
            fill="#2E3A59"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-down-arrow',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>