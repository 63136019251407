import {DateTimeAdapter} from '../adapters';

class Token {
    /**
     * @type {string}
     */
    token;
    /**
     * @type {DateTimeAdapter}
     */
    expires_at;

    /**
     *
     * @param {string} token
     * @param {string} expires_at
     */
    constructor(token, expires_at) {
        this.token = token;
        this.expires_at = DateTimeAdapter.create(expires_at || 'now')
    }

    /**
     * @returns {boolean}
     */
    get isValid() {
        return this.expires_at.moreThan(DateTimeAdapter.create());
    }

    /**
     *
     * @returns {{expires_at: *, token: string}}
     */
    toJSON() {
        return {
            token: this.token,
            expires_at: this.expires_at.toString()
        }
    }

    toString() {
        return this.token;
    }
}

export default Token;