/**
 * @property {int} id
 * @property {string} nome
 * @property {string} email
 * @property {string} avatar
 * @property {string} hmac
 * @property {int} org_id
 * @property {string} org_nome
 * @property {string} org_cidade
 * @property {string} org_estado
 * @property {string} org_avatar
 */
export default class UserInfo {
    id;
    nome;
    email;
    avatar;
    hmac;
    org_id;
    org_nome;
    org_cidade;
    org_estado;
    org_avatar;

    /**
     *
     * @param {int} id
     * @param {string} nome
     * @param {string} email
     * @param {string} foto
     * @param {string} hmac
     * @param {{id, nome, logo, cidade, estado: {sigla}}} cliente
     */
    constructor(
        id = 0,
        nome = '',
        email = '',
        foto = '',
        hash='',
        cliente = {}
    ) {
        this.id = id;
        this.nome = nome;
        this.email = email;
        this.avatar = foto;
        this.hmac = hash;
        this.org_id = cliente?.id || 0;
        this.org_nome = cliente?.nome || '';
        this.org_estado = cliente?.estado?.sigla || '';
        this.org_cidade = cliente?.cidade || '';
        this.org_avatar = cliente?.logo || '';
    }
}