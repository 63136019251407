<template>
    <svg
        viewBox="0 0 43 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M34.07 8.9109C34.18 7.2709 35.61 6.0509 37.2 6.1609C38.84 6.2709 40.11 7.6409 40.01 9.2909C39.9 10.9309 38.53 12.2009 36.88 12.1009C35.24 11.9909 33.97 10.5609 34.07 8.9209V8.9109Z"
              fill="#03A87D"/>
        <path
            d="M41.08 13.6109C40.86 13.0209 40.19 12.7109 39.59 12.9309C38.99 13.1509 38.69 13.8209 38.91 14.4209C39.77 16.7009 40.16 19.1109 40.08 21.5709C39.76 31.6409 31.42 39.6309 21.39 39.6309C21.2 39.6309 21.02 39.6309 20.83 39.6309C15.85 39.4709 11.23 37.3809 7.82002 33.7309C4.41002 30.0909 2.61 25.3409 2.77 20.3709C3.1 10.1109 11.73 2.0109 22.03 2.32089C25.67 2.44089 29.17 3.60089 32.14 5.68089C32.39 5.86089 32.7 5.93089 33 5.87089C33.3 5.82089 33.57 5.6509 33.75 5.4009C33.93 5.1509 34 4.8409 33.94 4.54089C33.88 4.24089 33.72 3.9709 33.47 3.79089C30.13 1.4509 26.2 0.140895 22.11 0.010895C10.54 -0.359105 0.840015 8.74089 0.470015 20.3009C0.290015 25.9009 2.30002 31.2409 6.13002 35.3209C9.96002 39.4109 15.16 41.7609 20.76 41.9409C20.99 41.9409 21.22 41.9509 21.45 41.9509C26.79 41.9509 31.88 39.9409 35.78 36.2809C39.87 32.4509 42.22 27.2509 42.4 21.6509C42.49 18.8809 42.04 16.1809 41.08 13.6109Z"
            fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M28.47 22.3909L24.07 32.6709C18.1 32.5609 21.94 25.3109 20.88 21.6009C24.47 19.9709 26.94 20.4409 28.47 22.3909Z"
              fill="#0A8C67"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M30.64 17.2409L28.47 22.3809C22.56 20.7109 10.66 23.1109 10.76 17.3109L26.98 13.3409C30.84 12.5709 32.49 12.8109 30.65 17.2509L30.64 17.2409Z"
              fill="#03A87D"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-empty',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>