<template>
    <svg
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M8.96875 0C9.28125 0 9.53125 0.1875 9.65625 0.4375L11.8125 4.84375L16.5938 5.5625C16.875 5.59375 17.0938 5.78125 17.1875 6.0625C17.2812 6.34375 17.2188 6.625 17 6.84375L13.5312 10.2812L14.3438 15.125C14.4062 15.4062 14.2812 15.7188 14.0625 15.875C13.8125 16.0312 13.5 16.0625 13.25 15.9375L8.96875 13.625L4.71875 15.9375C4.4375 16.0625 4.15625 16.0312 3.90625 15.875C3.6875 15.6875 3.5625 15.4062 3.625 15.125L4.4375 10.2812L0.96875 6.84375C0.75 6.625 0.6875 6.34375 0.78125 6.0625C0.875 5.8125 1.09375 5.59375 1.375 5.5625L6.15625 4.84375L8.3125 0.4375C8.4375 0.1875 8.6875 0 8.96875 0ZM8.96875 2.46875L7.34375 5.875C7.21875 6.09375 7.03125 6.25 6.78125 6.28125L3.09375 6.8125L5.75 9.46875C5.9375 9.65625 6.03125 9.90625 5.96875 10.125L5.34375 13.875L8.625 12.125C8.84375 12 9.125 12 9.34375 12.125L12.625 13.875L12 10.1562C11.9375 9.90625 12.0312 9.65625 12.2188 9.5L14.875 6.8125L11.1875 6.28125C10.9375 6.25 10.75 6.09375 10.625 5.875L8.96875 2.46875Z"
            fill="#0B132E"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-star',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>