<template>
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M7 9.25C6.57812 9.25 6.25 9.625 6.25 10V11.0781L14.3125 17.6875C15.2969 18.4844 16.6562 18.4844 17.6406 17.6875L25.75 11.0781V10C25.75 9.625 25.375 9.25 25 9.25H7ZM6.25 13.9844V22C6.25 22.4219 6.57812 22.75 7 22.75H25C25.375 22.75 25.75 22.4219 25.75 22V13.9844L19.0938 19.4219C17.2656 20.9219 14.6875 20.9219 12.9062 19.4219L6.25 13.9844ZM4 10C4 8.35938 5.3125 7 7 7H25C26.6406 7 28 8.35938 28 10V22C28 23.6875 26.6406 25 25 25H7C5.3125 25 4 23.6875 4 22V10Z"
            fill="#2E3F73"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-email',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>