import axios from 'axios';
import {UsuarioAutenticado, Token} from "../models"
import {UrlUtils} from "../utils"

class HttpClientAdapter {
    _config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    /**
     *
     * @type {AxiosInstance}
     */
    _http;
    /**
     *
     * @type {UsuarioAutenticado}
     */
    _usuarioAutenticado;

    /**
     *
     * @param {UsuarioAutenticado} usuarioAutenticado
     * @param {UrlUtils} urlApi
     */
    constructor(usuarioAutenticado, urlApi) {
        this._http = axios.create(this._config);
        this._usuarioAutenticado = usuarioAutenticado;
        this.#setToken(usuarioAutenticado.token)
        this.#configBaseUrl(urlApi)
        usuarioAutenticado.addObserver(UsuarioAutenticado.EVENT_SET_TOKEN, (token) => this.#setToken(token))
    }

    /**
     *
     * @returns {UsuarioAutenticado}
     */
    get usuarioAutenticado() {
        return this._usuarioAutenticado;
    }

    /**
     *
     * @param {string} url
     * @param {Object} params
     * @returns {Promise<AxiosResponse<any>>}
     */
    get(url, params) {
        this.#setConfig({params})
        return this._http.get(url, this._config)
            .then(response => response.data)
    }

    /**
     *
     * @param {string} url
     * @param {Object} data
     * @param {boolean} multipart
     * @returns {Promise<AxiosResponse<any>>}
     */
    post(url, data, multipart = false) {
        if (multipart) {
            this._config.headers['Content-Type'] = 'multipart/form-data'
        }
        return this._http.post(url, data, this._config)
            .then(response => response.data)
    }

    /**
     *
     * @param {string} url
     * @param {Object} data
     * @param {boolean} multipart
     * @returns {Promise<AxiosResponse<any>>}
     */
    put(url, data, multipart = false) {
        if (multipart) {
            this._config.headers['Content-Type'] = 'multipart/form-data'
        }
        return this._http.put(url, data, this._config)
            .then(response => response.data)
    }

    /**
     *
     * @param {string} url
     * @param {Object} data
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(url, data) {
        return this._http.delete(url, this._config)
            .then(response => response.data)
    }

    /**
     *
     * @param {string} url
     * @param {Object} data
     * @param {boolean} multipart
     * @returns {Promise<AxiosResponse<any>>}
     */
    patch(url, data, multipart = false) {
        if (multipart) {
            this._config.headers['Content-Type'] = 'multipart/form-data'
        }
        return this._http.patch(url, data, this._config)
            .then(response => response.data)
    }

    /**
     *
     * @param config
     */
    #setConfig(config) {
        this._config = Object.assign({}, this._config, config);
    }

    /**
     *
     * @param {UrlUtils} urlApi
     */
    #configBaseUrl(urlApi) {
        this.#setConfig({baseURL: urlApi.generateURL()});
    }

    /**
     *
     * @param {Token} token
     */
    #setToken(token) {
        if (!token.isValid) {
            this.#setConfig({
                headers: {
                    'Authorization': ''
                }
            });
            return;
        }
        this.#setConfig({
            headers: {
                'Authorization': `Bearer ${token.token}`
            }
        })
    }
}

export default HttpClientAdapter