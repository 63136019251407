<template>
    <svg
        viewBox="0 0 13 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M10.5 6C10.5 3.53125 8.46875 1.5 6 1.5C3.5 1.5 1.5 3.53125 1.5 6C1.5 6.40625 1.625 7 1.96875 7.8125C2.28125 8.5625 2.75 9.4375 3.28125 10.3125C4.1875 11.75 5.21875 13.125 6 14.125C6.75 13.125 7.78125 11.75 8.6875 10.3125C9.21875 9.4375 9.6875 8.5625 10 7.8125C10.3438 7 10.5 6.40625 10.5 6ZM12 6C12 8.75 8.34375 13.5938 6.71875 15.625C6.34375 16.0938 5.625 16.0938 5.25 15.625C3.65625 13.5938 0 8.75 0 6C0 2.6875 2.6875 0 6 0C9.3125 0 12 2.6875 12 6ZM7 6C7 5.46875 6.53125 5 6 5C5.4375 5 5 5.46875 5 6C5 6.5625 5.4375 7 6 7C6.53125 7 7 6.5625 7 6ZM3.5 6C3.5 5.125 3.96875 4.3125 4.75 3.84375C5.5 3.40625 6.46875 3.40625 7.25 3.84375C8 4.3125 8.5 5.125 8.5 6C8.5 6.90625 8 7.71875 7.25 8.1875C6.46875 8.625 5.5 8.625 4.75 8.1875C3.96875 7.71875 3.5 6.90625 3.5 6Z"
            fill="#0B132E"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-location',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>