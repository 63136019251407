<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.125 16.2812C5.25 15.8438 5.5 15.4375 5.8125 15.125L15.3125 5.625C16.0938 4.84375 17.375 4.84375 18.1562 5.625L19.375 6.84375C19.4688 6.9375 19.5625 7.0625 19.625 7.15625C20.1562 7.9375 20.0625 9 19.375 9.6875L9.875 19.1875C9.84375 19.2188 9.78125 19.25 9.75 19.3125C9.4375 19.5625 9.09375 19.75 8.71875 19.875L6.28125 20.5938L4.9375 21C4.6875 21.0625 4.40625 21 4.21875 20.7812C4 20.5938 3.9375 20.3125 4.03125 20.0625L4.40625 18.7188L5.125 16.2812ZM6.5625 16.7188L6.34375 17.4375L5.84375 19.1562L7.5625 18.6562L8.28125 18.4375C8.5 18.375 8.65625 18.2812 8.8125 18.125L15.9688 10.9688L14.0312 9.03125L6.875 16.1875C6.84375 16.1875 6.84375 16.2188 6.8125 16.25C6.6875 16.375 6.625 16.5312 6.5625 16.7188Z"
            fill="#5E58EA"
        />
    </svg>
</template>

<script>

export default {
    name: 'icon-edit',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>