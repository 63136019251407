<script>
export default {
    name: 'icon-download',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            default: '#E1E6FE'
        }
    }
}
</script>

<template>
    <svg :width="width" :height="height" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.25 19H7.75C7.3125 19 7 18.6875 7 18.25C7 17.8438 7.3125 17.5 7.75 17.5H18.25C18.6562 17.5 19 17.8438 19 18.25C19 18.6875 18.6562 19 18.25 19ZM12.4375 14.7812L8.4375 10.5312C8.15625 10.2188 8.1875 9.75 8.46875 9.46875C8.78125 9.1875 9.25 9.1875 9.53125 9.5L12.25 12.375V9.75V5.75C12.25 5.34375 12.5625 5 13 5C13.4062 5 13.75 5.34375 13.75 5.75V9.75V12.375L16.4375 9.5C16.7188 9.1875 17.1875 9.1875 17.5 9.46875C17.8125 9.75 17.8125 10.2188 17.5312 10.5312L13.5312 14.7812C13.375 14.9375 13.1875 15 13 15C12.7812 15 12.5938 14.9375 12.4375 14.7812Z"
            :fill="color"/>
    </svg>
</template>