<template>
    <svg
        viewBox="0 0 24 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M21.75 13C21.75 9.53125 19.875 6.34375 16.875 4.5625C13.8281 2.82812 10.125 2.82812 7.125 4.5625C4.07812 6.34375 2.25 9.53125 2.25 13C2.25 16.5156 4.07812 19.7031 7.125 21.4844C10.125 23.2188 13.8281 23.2188 16.875 21.4844C19.875 19.7031 21.75 16.5156 21.75 13ZM0 13C0 8.73438 2.25 4.79688 6 2.64062C9.70312 0.484375 14.25 0.484375 18 2.64062C21.7031 4.79688 24 8.73438 24 13C24 17.3125 21.7031 21.25 18 23.4062C14.25 25.5625 9.70312 25.5625 6 23.4062C2.25 21.25 0 17.3125 0 13ZM8.8125 7.9375C9.14062 7.70312 9.60938 7.70312 9.9375 7.9375L16.6875 12.0625C17.0156 12.25 17.25 12.625 17.25 13.0469C17.25 13.4219 17.0156 13.7969 16.6875 13.9844L9.9375 18.1094C9.60938 18.3438 9.14062 18.3438 8.8125 18.1562C8.4375 17.9219 8.25 17.5469 8.25 17.1719V8.875C8.25 8.5 8.4375 8.125 8.8125 7.9375Z"
            fill="#5E58EA"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-tutorial',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>