<template>
    <svg
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M6.46875 0.46875C6.75 0.1875 7.21875 0.1875 7.5 0.46875L13.5312 6.46875C13.8125 6.78125 13.8125 7.25 13.5312 7.53125C13.2188 7.84375 12.75 7.84375 12.4688 7.53125L7 2.0625L1.53125 7.53125C1.21875 7.84375 0.75 7.84375 0.46875 7.53125C0.15625 7.25 0.15625 6.78125 0.46875 6.5L6.46875 0.46875Z"
            fill="#4D3401"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-chevron-up',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>