import {DateTimeAdapter, HttpClientAdapter} from "../adapters";
import {Token, UsuarioAutenticado} from "../models";
import {UrlUtils} from "../utils";
import crypto from "crypto-js";
import router from "~/routes";

export class AuthService {
    static instance;
    #usuarioAutenticado;
    #httpClient;
    #authUrl;
    #state = '';
    #challenge = '';
    #verifier = '';
    #intended = '';

    constructor() {
        if (AuthService.instance) {
            return AuthService.instance;
        }
        this.#usuarioAutenticado = new UsuarioAutenticado();
        this.#authUrl = new UrlUtils(import.meta.env.VITE_APP_BUSSOLA_URL);
        this.#httpClient = new HttpClientAdapter(
            this.#usuarioAutenticado,
            this.#authUrl
        );
        this.#state = localStorage.getItem('state');
        this.#verifier = localStorage.getItem('verifier');
        this.#intended = localStorage.getItem('intended');

        AuthService.instance = this;
    }

    static getInstance() {
        return new AuthService();
    }

    authenticate() {
        if (this.isAuthenticated()) {
            return Promise.resolve();
        }
        this.#state = this.#createRandomString(40);
        const verifier = this.#createRandomString(128);
        this.#challenge = this.#base64Url(crypto.SHA256(verifier));
        localStorage.setItem('verifier', verifier);
        localStorage.setItem('state', this.#state)

        this.#authUrl.addSegment('oauth/authorize')
            .addQuery('client_id', import.meta.env.VITE_CLIENT_ID)
            .addQuery('redirect_uri', import.meta.env.VITE_APP_CALLBACK_URL)
            .addQuery('response_type', 'code')
            .addQuery('scope', '*')
            .addQuery('state', this.#state)
            .addQuery('code_challenge', this.#challenge)
            .addQuery('code_challenge_method', 'S256');
        return Promise.reject(this.#authUrl.generateURL())
    }

    callback() {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (!code && !state) {
            return;
        }

        if (state !== this.#state) {
            return;
        }

        const params = {
            grant_type: 'authorization_code',
            client_id: import.meta.env.VITE_CLIENT_ID,
            redirect_uri: import.meta.env.VITE_APP_CALLBACK_URL,
            code_verifier: this.#verifier,
            code: code
        }
        this.#httpClient.post(this.#authUrl.generateURL('oauth/token'), params)
            .then(
                (data) => {
                    const token = data.access_token
                    const expiresAt = new Date();
                    expiresAt.setSeconds(expiresAt.getSeconds() + data.expires_in);
                    this.#usuarioAutenticado.token = new Token(token, expiresAt.toISOString());
                    localStorage.setItem('token', JSON.stringify(this.#usuarioAutenticado.token));
                    localStorage.setItem('state', '');
                    localStorage.setItem('verifier', '');
                    sessionStorage.removeItem('check-token');
                    router.replace({path: this.#intended || '/'});
                },
            )
    }

    isAuthenticated() {
        const checkToken = sessionStorage.getItem('check-token');
        if (checkToken) {
            sessionStorage.setItem('check-token', 'false');
            localStorage.removeItem('token');
        }
        const storage = JSON.parse(localStorage.getItem('token'));
        if (!storage) {
            return false;
        }
        this.#usuarioAutenticado.token = new Token(storage.token, storage.expires_at);
        return this.#usuarioAutenticado.token.isValid
    }

    logout() {
        localStorage.removeItem('token');
        location.href = this.#authUrl.addSegment('logout').addQuery('mobile_encontros', 1).generateURL()
    }

    get token() {
        return this.#usuarioAutenticado.token.token || ''
    }

    get userId() {
        return this.#parseJwt().sub
    }

    get intendedPath() {
        return this.#intended
    }

    set intendedPath(location) {
        const intended = `${location.path}${this.#parseQuery(location.query)}`
        localStorage.setItem(
            'intended',
            intended
        )
        this.#intended = intended
    }

    /**
     *
     * @returns {{aud, exp, iat, jti, nbf, scopes, sub}}
     */
    #parseJwt() {
        try {
            const base64Url = this.token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join(''));
            return JSON.parse(jsonPayload);
        } catch (e) {
            return {
                aud: null,
                exp: null,
                iat: null,
                jti: null,
                nbf: null,
                sub: null,
                scopes: []
            }
        }
    }

    #parseQuery(query) {
        if (!query) return '';
        if (Object.keys(query).length === 0) {
            return '';
        }
        return '?' + Object.keys(query).map((key) => {
                return `${key}=${query[key]}`
            }
        ).join('&');
    }

    #base64Url(string) {
        return string.toString(crypto.enc.Base64)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }

    #createRandomString(num) {
        return [...Array(num)].map(() => Math.random().toString(36)[2]).join('')
    }
}
