<template>
    <svg
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M17 6.5C17 10.0938 13.4062 13 9 13C7.8125 13 6.71875 12.8125 5.71875 12.4688C5.375 12.7188 4.75 13.0938 4.03125 13.4062C3.28125 13.75 2.375 14 1.5 14C1.28125 14 1.09375 13.9062 1.03125 13.7188C0.9375 13.5312 1 13.3125 1.125 13.1562C1.15625 13.1562 1.15625 13.125 1.1875 13.0938C1.21875 13.0625 1.28125 13 1.34375 12.9375C1.46875 12.7812 1.625 12.5312 1.8125 12.25C2.125 11.75 2.40625 11.0625 2.46875 10.2812C1.53125 9.21875 1 7.9375 1 6.5C1 2.9375 4.5625 0 9 0C13.4062 0 17 2.9375 17 6.5Z"
            fill="#5E58EA"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-comment-filled',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>
