<template>
    <svg
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M14.5 9C14.5 6.6875 13.25 4.5625 11.25 3.375C9.21875 2.21875 6.75 2.21875 4.75 3.375C2.71875 4.5625 1.5 6.6875 1.5 9C1.5 11.3438 2.71875 13.4688 4.75 14.6562C6.75 15.8125 9.21875 15.8125 11.25 14.6562C13.25 13.4688 14.5 11.3438 14.5 9ZM0 9C0 6.15625 1.5 3.53125 4 2.09375C6.46875 0.65625 9.5 0.65625 12 2.09375C14.4688 3.53125 16 6.15625 16 9C16 11.875 14.4688 14.5 12 15.9375C9.5 17.375 6.46875 17.375 4 15.9375C1.5 14.5 0 11.875 0 9ZM7.25 4.75C7.25 4.34375 7.5625 4 8 4C8.40625 4 8.75 4.34375 8.75 4.75V8.625L11.4062 10.375C11.75 10.625 11.8438 11.0938 11.625 11.4375C11.375 11.7812 10.9062 11.875 10.5625 11.625L7.5625 9.625C7.375 9.5 7.25 9.25 7.25 9V4.75Z"
            fill="#0B132E"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-clock',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>