<template>
    <section class="loading">
        <div id="splash"></div>
    </section>
</template>
<script setup>
import {ref, onMounted, watch, computed} from 'vue';
import {useRouter} from "vue-router";
import Lottie from 'lottie-web';
import {AuthService} from "../core/services/AuthService.js";
import {useUserInfo} from "../composables/useUserInfo.js";
import SplashScreenJson from '../assets/img/splash-screen.json'
import {useOnline} from "@vueuse/core";

let animation = null;
const emit = defineEmits(['loaded'])

const router = useRouter()
const currentRoute = computed(() => router.currentRoute.value)
const {userInfoLoaded, hasUserNotAuthorized} = useUserInfo()
const isOnline = useOnline()

const checkAuth = async () => {
    if (hasUserNotAuthorized.value) {
        await router.replace({name: 'error'})
        return
    }
    const authService = AuthService.getInstance()
    const path = authService.intendedPath === '/' ? '/agenda' : authService.intendedPath
    if (router.currentRoute.value.name === 'callback') {
        if (authService.isAuthenticated()) {
            router.replace(path).then(
                () => {
                    emit('loaded')
                    animation.destroy()
                }
            )
        } else {
            authService.callback()
        }
    } else if (userInfoLoaded.value) {
        router.replace(path).then(
            () => {
                emit('loaded')
                animation.destroy()
            }
        )
    }
}

onMounted(async () => {
    animation = Lottie.loadAnimation({
        container: document.getElementById('splash'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: SplashScreenJson,
    });
    animation.addEventListener('loopComplete', async () => {
        if (currentRoute.value.meta?.public && currentRoute.value.name !== 'callback') {
            emit('loaded')
        } else {
            await checkAuth()
        }
    })
})
</script>
<style lang="scss" scoped>
.loading {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    font-size: 100px;
    font-weight: 700;
    text-align: center;
    background-color: rgba(94, 88, 234, 1);

    #splash {
        padding: 55px;
    }
}
</style>