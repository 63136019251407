export default class UrlUtils {
    _url;
    _segments = [];
    _query = {};

    /**
     *
     * @param {string} url
     */
    constructor(url) {
        this._url = url;
    }

    /**
     *
     * @param {string} url
     * @returns {UrlUtils}
     */
    static instance(url) {
        return new UrlUtils(url);
    }

    /**
     *
     * @param segment
     * @returns {UrlUtils}
     */
    addSegment(segment) {
        this._segments.push(segment);
        return this;
    }

    /**
     *
     * @param key
     * @param value
     * @returns {UrlUtils}
     */
    addQuery(key, value) {
        this._query[key] = value;
        return this;
    }

    /**
     *
     * @param {string} segment
     * @returns {string}
     */
    generateURL(segment = '') {
        if (segment) {
            this.addSegment(segment);
        }
        const segments = this.#generateSegments();
        const query = this.#generateQuery();
        const url = this.#addSlashIfNeeded(this._url, segments) + (query ? `?${query}` : '');
        this.#clear();
        return url;
    }

    #clear() {
        this._query = [];
        this._segments = [];
    }

    /**
     *
     * @param {string} url
     * @param {string} segment
     * @returns {string}
     */
    #addSlashIfNeeded(url, segment = '') {
        if (url.slice(-1) !== '/' && segment.slice(0, 1) !== '/') {
            url += '/';
        }
        return url + segment;
    }

    #generateSegments() {
        let segments = '';
        this._segments.forEach((segment) => {
            segments = this.#addSlashIfNeeded(segments, segment);
        });
        return segments;
    }

    #generateQuery() {
        let query = '';
        Object.keys(this._query).forEach((key) => {
            query += `${key}=${this._query[key]}&`;
        });
        return query.slice(0, -1);
    }
}