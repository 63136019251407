<template>
    <svg
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M15.7812 7.53125L11.7812 11.5312C11.4688 11.8438 11 11.8438 10.7188 11.5312C10.4062 11.25 10.4062 10.7812 10.7188 10.4688L13.4375 7.75H5.75C5.3125 7.75 5 7.4375 5 7C5 6.59375 5.3125 6.25 5.75 6.25H13.4375L10.7188 3.53125C10.4062 3.25 10.4062 2.78125 10.7188 2.46875C11 2.1875 11.4688 2.1875 11.7812 2.46875L15.7812 6.5C16.0625 6.78125 16.0625 7.25 15.7812 7.53125ZM5.25 1.5H2.75C2.03125 1.5 1.5 2.0625 1.5 2.75V11.25C1.5 11.9688 2.03125 12.5 2.75 12.5H5.25C5.65625 12.5 6 12.8438 6 13.25C6 13.6875 5.65625 14 5.25 14H2.75C1.21875 14 0 12.7812 0 11.25V2.75C0 1.25 1.21875 0 2.75 0H5.25C5.65625 0 6 0.34375 6 0.75C6 1.1875 5.65625 1.5 5.25 1.5Z"
            fill="#5E58EA"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-logoff',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>