<template>
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <rect width="32" height="32" rx="16" fill="#90EBD3"/>
        <path
            d="M22.7812 11.2188C23.0625 11.5312 23.0625 12 22.7812 12.2812L14.5312 20.5312C14.2188 20.8438 13.75 20.8438 13.4688 20.5312L9.21875 16.2812C8.90625 16 8.90625 15.5312 9.21875 15.25C9.5 14.9375 9.96875 14.9375 10.25 15.25L13.9688 18.9688L21.7188 11.2188C22 10.9375 22.4688 10.9375 22.75 11.2188H22.7812Z"
            fill="#113F39"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-check-background',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>