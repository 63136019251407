<template>
    <svg
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M11.7188 2.75C12.4805 2.75 13.125 3.39453 13.125 4.15625V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V4.15625C0 3.39453 0.615234 2.75 1.40625 2.75H2.8125V1.22656C2.8125 1.05078 2.95898 0.875 3.16406 0.875H4.33594C4.51172 0.875 4.6875 1.05078 4.6875 1.22656V2.75H8.4375V1.22656C8.4375 1.05078 8.58398 0.875 8.78906 0.875H9.96094C10.1367 0.875 10.3125 1.05078 10.3125 1.22656V2.75H11.7188ZM11.543 14.4688C11.6309 14.4688 11.7188 14.4102 11.7188 14.293V5.5625H1.40625V14.293C1.40625 14.4102 1.46484 14.4688 1.58203 14.4688H11.543Z"
            fill="#2E3A59"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-date',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>