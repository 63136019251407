<template>
    <svg
        :width="width"
        :height="height"
        :color="color"
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.32812 23.2969L0.828125 18.7969C0.359375 18.375 0.359375 17.6719 0.828125 17.2031L5.32812 12.75C5.75 12.2812 6.45312 12.2812 6.875 12.75C7.34375 13.1719 7.34375 13.875 6.875 14.2969L4.29688 16.875H20.375C20.9844 16.875 21.5 17.3906 21.5 18C21.5 18.6562 20.9844 19.125 20.375 19.125L4.29688 19.1719L6.875 21.75C7.34375 22.1719 7.34375 22.875 6.875 23.2969C6.45312 23.7656 5.75 23.7656 5.32812 23.2969ZM21.1719 6.79688L16.6719 11.2969C16.2031 11.7656 15.5 11.7656 15.0781 11.2969C14.6094 10.875 14.6094 10.1719 15.0781 9.70312L17.6562 7.125H1.625C0.96875 7.125 0.5 6.65625 0.5 6C0.5 5.39062 0.96875 4.875 1.625 4.875H17.6562L15.0781 2.29688C14.6094 1.875 14.6094 1.17188 15.0781 0.703125C15.5 0.28125 16.2031 0.28125 16.6719 0.703125L21.1719 5.25C21.5938 5.67188 21.5938 6.375 21.1719 6.79688Z"
            fill="#5E58EA"/>
    </svg>
</template>

<script>
export default {
    name: 'icon-alternate',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>