<template>
    <svg
        :width="width"
        :height="height"
        :color="color"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 6.5C9.65625 6.5 7.53125 7.75 6.34375 9.75C5.1875 11.7812 5.1875 14.25 6.34375 16.25C7.53125 18.2812 9.65625 19.5 12 19.5C14.3125 19.5 16.4375 18.2812 17.625 16.25C18.7812 14.25 18.7812 11.7812 17.625 9.75C16.4375 7.75 14.3125 6.5 12 6.5ZM12 21C9.125 21 6.5 19.5 5.0625 17C3.625 14.5312 3.625 11.5 5.0625 9C6.5 6.53125 9.125 5 12 5C14.8438 5 17.4688 6.53125 18.9062 9C20.3438 11.5 20.3438 14.5312 18.9062 17C17.4688 19.5 14.8438 21 12 21ZM10.75 15.5H11.5V13.5H10.75C10.3125 13.5 10 13.1875 10 12.75C10 12.3438 10.3125 12 10.75 12H12.25C12.6562 12 13 12.3438 13 12.75V15.5H13.25C13.6562 15.5 14 15.8438 14 16.25C14 16.6875 13.6562 17 13.25 17H10.75C10.3125 17 10 16.6875 10 16.25C10 15.8438 10.3125 15.5 10.75 15.5ZM12 11C11.4375 11 11 10.5625 11 10C11 9.46875 11.4375 9 12 9C12.5312 9 13 9.46875 13 10C13 10.5625 12.5312 11 12 11Z"
            :fill="color"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-info',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>