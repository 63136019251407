const routes = [
    {
        path: '/',
        name: 'loading',
        meta: {
            layout: "default"
        },
        redirect: {name: 'schedule'}
    },
    {
        path: '/callback',
        name: 'callback',
        component: async () => import('~/layout/LayoutEmpty.vue'),
        meta: {
            layout: "default",
            public: true
        },
    },
    {
        path: '/agenda',
        name: 'schedule',
        component: () => import('~/views/Agenda/Agenda.vue'),
        meta: {
            title: "Agenda",
        },
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        component: () => import('~/components/Tutorial/TutorialStepper.vue'),
        meta: {layout: "no-header"},
    },
    {
        path: '/ajuda',
        name: 'help',
        component: () => import('~/views/Ajuda/Ajuda.vue'),
        meta: {
            title: 'Ajuda'
        }
    },
    {
        path: '/relato',
        name: 'report-problem',
        component: () => import('~/components/ReportProblem/ReportProblem.vue'),
        meta: {
            title: 'Relatar um problema'
        }
    },
    {
        path: '/agenda/detalhes/:id',
        name: 'schedule-details',
        component: () => import('~/views/Agenda/Detalhes.vue'),
        meta: {
            title: "Detalhes do encontro ",
        },
    },
    {
        path: '/registrar/:id',
        name: 'register-meeting',
        component: () => import('~/views/Registrar/Registrar.vue'),
        meta: {
            title: "Registrar encontro",
        },
    },
    {
        path: '/cancelar/:id',
        name: 'cancel-meeting',
        component: () => import('~/views/Cancelar/Cancelar.vue'),
        meta: {
            title: "Cancelar encontro ",
        },
    },
    {
        path: '/reagendar/:id',
        name: 'reschedule',
        component: () => import('~/views/Reagendar/Reagendar.vue'),
        meta: {
            title: "Reagendar encontro",
        },
    },
    {
        path: '/minhas-organizacoes/',
        name: 'my-organizations',
        component: () => import('~/views/Organizacoes/Organizacoes.vue'),
        meta: {
            title: "",
        },
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('~/defaultScreen/ErrorScreen.vue'),
        meta: {
            layout: "no-header",
            public: true
        },
    },
    {
        path: '/offline',
        name: 'offline',
        component: () => import("~/defaultScreen/OfflineScreen.vue"),
        meta: {
            layout: "no-header",
            public: true
        },
    },
    {
        path: '/relato-sucesso',
        name: 'report-success',
        component: () => import('~/defaultScreen/ReportSuccessScreen.vue'),
        meta: {
            layout: "no-header",
            public: false
        },
    },
    {
        path: '/promotion-page',
        name: 'promotion-page',
        component: () => import('~/defaultScreen/PromotionPage.vue'),
        meta: {
            layout: "no-header",
            public: true
        },
    },
    {
        path: '/sale-screen',
        name: 'sale-screen',
        component: () => import('~/defaultScreen/SaleScreen/SaleScreen.vue'),
        meta: {
            layout: "no-header",
            public: true
        },
    }
]

export default routes;
