<template>
    <svg
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M6.25 11.0312C7.0625 11.3438 8 11.5 9 11.5C12.875 11.5 15.5 9 15.5 6.5C15.5 4.03125 12.875 1.5 9 1.5C5.09375 1.5 2.5 4.03125 2.5 6.5C2.5 7.5 2.875 8.46875 3.59375 9.3125C3.875 9.59375 4 10 3.96875 10.4062C3.9375 10.9688 3.78125 11.5 3.625 11.9375C4.15625 11.7188 4.59375 11.4375 4.84375 11.25C5.25 10.9375 5.78125 10.875 6.25 11.0312ZM1.8125 12.25C2.125 11.75 2.40625 11.0625 2.46875 10.2812C1.53125 9.21875 1 7.9375 1 6.5C1 2.9375 4.5625 0 9 0C13.4062 0 17 2.9375 17 6.5C17 10.0938 13.4062 13 9 13C7.84375 13 6.75 12.8125 5.75 12.4688C5.375 12.7188 4.75 13.0938 4.03125 13.4062C3.5625 13.625 3.03125 13.8125 2.46875 13.9062C2.46875 13.9062 2.4375 13.9375 2.40625 13.9375C2.28125 13.9688 2.125 13.9688 2 14H1.96875C1.8125 14 1.65625 14.0312 1.5 14.0312C1.28125 14.0312 1.09375 13.9062 1.03125 13.7188C0.9375 13.5312 1 13.3125 1.125 13.1562C1.25 13.0312 1.375 12.875 1.5 12.75C1.53125 12.6562 1.59375 12.5938 1.625 12.5312L1.65625 12.5C1.71875 12.4375 1.75 12.3438 1.8125 12.25ZM8.25 9.25V7.25H6.25C5.8125 7.25 5.5 6.9375 5.5 6.5C5.5 6.09375 5.8125 5.75 6.25 5.75H8.25V3.75C8.25 3.34375 8.5625 3 9 3C9.40625 3 9.75 3.34375 9.75 3.75V5.75H11.75C12.1562 5.75 12.5 6.09375 12.5 6.5C12.5 6.9375 12.1562 7.25 11.75 7.25H9.75V9.25C9.75 9.6875 9.40625 10 9 10C8.5625 10 8.25 9.6875 8.25 9.25Z"
            fill="#5E58EA"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-comment',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>