<template>
    <svg
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M10.5312 2.28125L6.78125 6.03125L10.5 9.75C10.8125 10.0312 10.8125 10.5 10.5 10.7812C10.2188 11.0938 9.75 11.0938 9.46875 10.7812L5.71875 7.0625L2 10.7812C1.71875 11.0938 1.25 11.0938 0.96875 10.7812C0.65625 10.5 0.65625 10.0312 0.96875 9.71875L4.6875 6L0.96875 2.28125C0.65625 2 0.65625 1.53125 0.96875 1.21875C1.25 0.9375 1.71875 0.9375 2.03125 1.21875L5.75 4.96875L9.46875 1.25C9.75 0.9375 10.2188 0.9375 10.5312 1.25C10.8125 1.53125 10.8125 2 10.5312 2.28125Z"
            :fill="color"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-close',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>