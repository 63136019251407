<template>
    <svg
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M6.28125 1.5C6.1875 1.5 6.125 1.5625 6.0625 1.625L5.46875 2.5H10L9.40625 1.625C9.375 1.5625 9.28125 1.5 9.1875 1.5H6.28125ZM11.8125 2.5H12.2188H13.75H14C14.4062 2.5 14.75 2.84375 14.75 3.25C14.75 3.6875 14.4062 4 14 4H13.625L12.875 14.1562C12.7812 15.2188 11.9375 16 10.875 16H4.59375C3.53125 16 2.6875 15.2188 2.59375 14.1562L1.84375 4H1.5C1.0625 4 0.75 3.6875 0.75 3.25C0.75 2.84375 1.0625 2.5 1.5 2.5H1.75H3.25H3.65625L4.8125 0.78125C5.125 0.3125 5.6875 0 6.28125 0H9.1875C9.78125 0 10.3438 0.3125 10.6562 0.78125L11.8125 2.5ZM12.125 4H3.34375L4.09375 14.0625C4.125 14.3125 4.34375 14.5 4.59375 14.5H10.875C11.125 14.5 11.3438 14.3125 11.375 14.0625L12.125 4Z"
            fill="#5E58EA"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-eliminate',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>