import BaseRepository from "./BaseRepository";
import {UserInfo} from "../models";
import {AuthService} from "~/core/services/AuthService.js";

export default class UserInfoRepository extends BaseRepository {
    #url = 'pedagogico/v1/usuarios'

    /**
     *
     * @param id
     * @returns {Promise<UserInfo>}
     */
    get(id) {
        return this._httpAdapter.get(`${this.#url}/${id}`, {}).then(
            ({data}) => {
                return new UserInfo(
                    data.id,
                    data.nome,
                    data.email,
                    data.foto,
                    data.hmac,
                    data.cliente
                )
            },
            ({response}) => {
                if (response.status === 401) {
                    AuthService.getInstance().logout()
                } else {
                    return Promise.reject(response)
                }
            }
        )
    }

}