<template>
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <rect width="32" height="32" rx="16" fill="#FEADB2"/>
        <path
            d="M15.75 10.6562L9.5625 20.8125C9.5 20.9062 9.5 20.9688 9.5 21.0625C9.5 21.3125 9.6875 21.5 9.9375 21.5H22.0312C22.2812 21.5 22.5 21.3125 22.5 21.0625C22.5 20.9688 22.4688 20.9062 22.4062 20.8125L16.2188 10.6562C16.1875 10.5625 16.0938 10.5 16 10.5C15.875 10.5 15.8125 10.5625 15.75 10.6562ZM14.4688 9.875C14.7812 9.34375 15.375 9 16 9C16.5938 9 17.1875 9.34375 17.5 9.875L23.6875 20.0312C23.875 20.3438 24 20.6875 24 21.0625C24 22.125 23.125 23 22.0312 23H9.9375C8.875 23 8 22.125 8 21.0625C8 20.6875 8.09375 20.3438 8.28125 20.0312L14.4688 9.875ZM17 19.5C17 20.0625 16.5312 20.5 16 20.5C15.4375 20.5 15 20.0625 15 19.5C15 18.9688 15.4375 18.5 16 18.5C16.5312 18.5 17 18.9688 17 19.5ZM16.75 13.75V16.75C16.75 17.1875 16.4062 17.5 16 17.5C15.5625 17.5 15.25 17.1875 15.25 16.75V13.75C15.25 13.3438 15.5625 13 16 13C16.4062 13 16.75 13.3438 16.75 13.75Z"
            fill="#4D3401"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-eliminate-background',
    props: {
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    }
}
</script>