import {UsuarioAutenticado} from "../models/index.js";
import {HttpClientAdapter} from "../adapters/index.js";
import {UrlUtils} from "../utils/index.js";

const usuarioAutenticado = UsuarioAutenticado.getInstance();

class HttpServerFactory {
    constructor() {
        this._httpServer = null;
    }

    /**
     *
     * @returns {HttpClientAdapter}
     */
    static createBussolaApiHttpServer() {
        this._httpServer = new HttpClientAdapter(
            usuarioAutenticado,
            UrlUtils.instance(
                import.meta.env.VITE_APP_BUSSOLA_API_URL
            )
        );
        return this._httpServer;
    }
}

export default HttpServerFactory;