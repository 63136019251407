<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.5 13C9.59375 13 8.78125 12.5312 8.3125 11.75C7.875 11 7.875 10.0312 8.3125 9.25C8.78125 8.5 9.59375 8 10.5 8C11.375 8 12.1875 8.5 12.6562 9.25C13.0938 10.0312 13.0938 11 12.6562 11.75C12.1875 12.5312 11.375 13 10.5 13ZM22 13C21.0938 13 20.2812 12.5312 19.8125 11.75C19.375 11 19.375 10.0312 19.8125 9.25C20.2812 8.5 21.0938 8 22 8C22.875 8 23.6875 8.5 24.1562 9.25C24.5938 10.0312 24.5938 11 24.1562 11.75C23.6875 12.5312 22.875 13 22 13ZM6 17.3438C6 15.5 7.46875 14 9.3125 14H10.6562C11.1562 14 11.625 14.125 12.0625 14.3125C12 14.5312 12 14.7812 12 15C12 16.2188 12.5 17.2812 13.3438 18H13.3125H6.65625C6.28125 18 6 17.7188 6 17.3438ZM18.6562 18H18.625C19.4688 17.2812 20 16.2188 20 15C20 14.7812 19.9688 14.5312 19.9375 14.3125C20.3438 14.125 20.8125 14 21.3125 14H22.6562C24.5 14 26 15.5 26 17.3438C26 17.7188 25.6875 18 25.3125 18H18.6562ZM16 13.5C15.4375 13.5 14.9688 13.8125 14.6875 14.25C14.4062 14.7188 14.4062 15.3125 14.6875 15.75C14.9688 16.2188 15.4375 16.5 16 16.5C16.5312 16.5 17 16.2188 17.2812 15.75C17.5625 15.3125 17.5625 14.7188 17.2812 14.25C17 13.8125 16.5312 13.5 16 13.5ZM16 18C14.9062 18 13.9375 17.4375 13.375 16.5C12.8438 15.5938 12.8438 14.4375 13.375 13.5C13.9375 12.5938 14.9062 12 16 12C17.0625 12 18.0312 12.5938 18.5938 13.5C19.125 14.4375 19.125 15.5938 18.5938 16.5C18.0312 17.4375 17.0625 18 16 18ZM14.1562 20.5C12.9062 20.5 11.875 21.375 11.5625 22.5H20.4062C20.0938 21.375 19.0625 20.5 17.8125 20.5H14.1562ZM14.1562 19H17.8125C20.125 19 22 20.875 22 23.1875C22 23.6562 21.625 24 21.1562 24H10.8125C10.3438 24 10 23.625 10 23.1875C10 20.875 11.8438 19 14.1562 19Z"
            fill="#5E58EA"/>
    </svg>
</template>

<script>

export default {
    name: 'icon-people',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>