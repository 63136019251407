<template>
    <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M21.5781 16.9375L26.8281 19.1875C27.6719 19.5156 28.1406 20.4062 27.9531 21.2969L26.8281 26.5469C26.6406 27.3906 25.8438 28.0469 25 28.0469C24.6719 28.0469 24.3906 28 24.1094 28C23.6406 28 23.1719 27.9531 22.75 27.9062C12.2031 26.7812 4 17.875 4 7C4 6.15625 4.60938 5.35938 5.45312 5.17188L10.7031 4.04688C11.5938 3.85938 12.4844 4.32812 12.8125 5.17188L15.0625 10.4219C15.3906 11.1719 15.2031 12.0625 14.5469 12.5781L12.625 14.1719C13.8906 16.3281 15.6719 18.1094 17.8281 19.375L19.4219 17.4531C19.9375 16.7969 20.8281 16.6094 21.5781 16.9375ZM24.6719 25.75L25.6562 21.1094L20.9688 19.0938L19.6094 20.7812C18.9062 21.625 17.6875 21.8594 16.7031 21.2969C14.2188 19.8438 12.1562 17.7812 10.7031 15.2969C10.1406 14.3125 10.375 13.0938 11.2188 12.3906L12.9062 11.0312L10.8906 6.34375L6.25 7.32812C6.39062 17.4531 14.5469 25.6094 24.6719 25.75Z"
            fill="#2E3F73"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-phone',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>