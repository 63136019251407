import {Permissao, Token} from "./index";
import {Observable} from "../utils";
import {DateTimeAdapter} from "../adapters";

class UsuarioAutenticado extends Observable {
    #data = DateTimeAdapter.create();
    /**
     * @type {Token}
     */
    #token = null;
    /**
     * @type {Permissao[]}
     */
    #permissoes = [];

    static EVENT_SET_TOKEN = 'set-token';
    static EVENT_SET_PERMISSOES = 'set-permissoes';

    constructor() {
        if (!UsuarioAutenticado._instance) {
            super();
            this.#token = new Token();
            UsuarioAutenticado._instance = this;
        }
        return UsuarioAutenticado._instance;
    }

    static getInstance() {
        return new UsuarioAutenticado();
    }

    /**
     *
     * @param {Token} token
     */
    set token(token) {
        this.#token = token;
        this._notify(UsuarioAutenticado.EVENT_SET_TOKEN, token);
    }

    get token() {
        return this.#token;
    }

    /**
     *
     * @returns {Object}
     */
    get data() {
        return this.#data;
    }

    /**
     *
     * @param {Object} data
     */
    set data(data) {
        this.#data = data;
    }

    /**
     * @param {Permissao[]} permissoes
     */
    set permissoes(permissoes) {
        this.#permissoes = permissoes;
        this._notify(UsuarioAutenticado.EVENT_SET_PERMISSOES, permissoes);
    }

    get permissoes() {
        return this.#permissoes;
    }

    /**
     *
     * @param {string} key
     * @returns {any}
     */
    get(key) {
        return this.#data[key];
    }
}

export default UsuarioAutenticado;