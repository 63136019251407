<template>
    <svg
        :width="width"
        :height="height"
        :color="color"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="32" height="32" rx="16" fill="#A9EAFF"/>
        <path
            d="M16 9.5C13.6562 9.5 11.5312 10.75 10.3438 12.75C9.1875 14.7812 9.1875 17.25 10.3438 19.25C11.5312 21.2812 13.6562 22.5 16 22.5C18.3125 22.5 20.4375 21.2812 21.625 19.25C22.7812 17.25 22.7812 14.7812 21.625 12.75C20.4375 10.75 18.3125 9.5 16 9.5ZM16 24C13.125 24 10.5 22.5 9.0625 20C7.625 17.5312 7.625 14.5 9.0625 12C10.5 9.53125 13.125 8 16 8C18.8438 8 21.4688 9.53125 22.9062 12C24.3438 14.5 24.3438 17.5312 22.9062 20C21.4688 22.5 18.8438 24 16 24ZM14.75 18.5H15.5V16.5H14.75C14.3125 16.5 14 16.1875 14 15.75C14 15.3438 14.3125 15 14.75 15H16.25C16.6562 15 17 15.3438 17 15.75V18.5H17.25C17.6562 18.5 18 18.8438 18 19.25C18 19.6875 17.6562 20 17.25 20H14.75C14.3125 20 14 19.6875 14 19.25C14 18.8438 14.3125 18.5 14.75 18.5ZM16 14C15.4375 14 15 13.5625 15 13C15 12.4688 15.4375 12 16 12C16.5312 12 17 12.4688 17 13C17 13.5625 16.5312 14 16 14Z"
            fill="#06364B"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-info-background',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>