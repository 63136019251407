export class Observable {
    _observers = {};

    constructor() {
        this._observers = {};
    }

    /**
     *
     * @param {string} event
     * @param {Function} callback
     */
    addObserver(event, callback) {
        if (!this._observers[event]) {
            this._observers[event] = [];
        }
        this._observers[event].push(callback);
    }

    _notify(event, data) {
        if (this._observers[event]) {
            this._observers[event].forEach((callback) => {
                callback(data);
            });
        }
    }
}