<template>
    <svg
        viewBox="0 0 24 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :color="color"
    >
        <path
            d="M21.75 13C21.75 9.53125 19.875 6.34375 16.875 4.5625C13.8281 2.82812 10.125 2.82812 7.125 4.5625C4.07812 6.34375 2.25 9.53125 2.25 13C2.25 16.5156 4.07812 19.7031 7.125 21.4844C10.125 23.2188 13.8281 23.2188 16.875 21.4844C19.875 19.7031 21.75 16.5156 21.75 13ZM0 13C0 8.73438 2.25 4.79688 6 2.64062C9.70312 0.484375 14.25 0.484375 18 2.64062C21.7031 4.79688 24 8.73438 24 13C24 17.3125 21.7031 21.25 18 23.4062C14.25 25.5625 9.70312 25.5625 6 23.4062C2.25 21.25 0 17.3125 0 13ZM7.92188 8.78125C8.29688 7.70312 9.28125 7 10.4062 7H13.125C14.7656 7 16.125 8.35938 16.125 10C16.125 11.0312 15.5156 12.0156 14.625 12.5312L13.125 13.4219C13.0781 14.0312 12.6094 14.5 12 14.5C11.3438 14.5 10.875 14.0312 10.875 13.375V12.7656C10.875 12.3438 11.0625 11.9688 11.4375 11.7812L13.5 10.6094C13.7344 10.4688 13.875 10.2344 13.875 10C13.875 9.57812 13.5469 9.29688 13.125 9.29688H10.4062C10.2656 9.29688 10.125 9.39062 10.0781 9.53125L10.0312 9.57812C9.84375 10.1875 9.1875 10.4688 8.625 10.2812C8.01562 10.0469 7.73438 9.39062 7.92188 8.82812V8.78125ZM10.5 17.5C10.5 16.7031 11.1562 16 12 16C12.7969 16 13.5 16.7031 13.5 17.5C13.5 18.3438 12.7969 19 12 19C11.1562 19 10.5 18.3438 10.5 17.5Z"
            fill="#5E58EA"/>
    </svg>
</template>
<script>
export default {
    name: 'icon-interrogation',
    props: {
        width: {
            type: Number,
            required: true
        },

        height: {
            type: Number,
            required: true
        },

        color: {
            type: String,
            required: true
        }
    }
}
</script>