import {HttpClientAdapter} from "../adapters/index.js";

export default class BaseRepository {
    /**
     * @type {HttpClientAdapter}
     * @private
     */
    _httpAdapter;

    /**
     *
     * @param httpAdapter {HttpClientAdapter}
     */
    constructor(httpAdapter) {
        this._httpAdapter = httpAdapter;
    }
}