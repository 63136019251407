<template>
    <div class="optionLink" @click="$router.push({path: link})">
        <div class="optionLink__icon">
            <pw-icon
                :name="icon"
                :size="24"
            />
            <p>{{ label }}</p>
        </div>
        <button
            class="btn"
            @click="openLink"
            v-if="linkIcon"
        >
            <pw-icon
                :name="linkIcon"
                :size="18"
            />
        </button>
    </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router'
import PwIcon from '~/pwComponents/PwIcon/PwIcon.vue';

const props = defineProps({
    label: {
        type: String
    },

    icon: {
        type: String
    },

    link: {
        type: String,
        default: ''
    },

    linkIcon: {
        type: String,
        default: ''
    }
})

const router = useRouter()

const openLink = () => {
    if (props.link) {
        router.push({path: props.link})
    }
}
</script>

<style lang="scss">
@import './OptionLink.scss';
</style>