import {createRouter, createWebHistory} from "vue-router";
import routes from "./routes";
import {authMiddleware} from "./middlewares/authMiddleware.js";
import {sawTutorialMiddleware} from "~/routes/middlewares/sawTutorialMiddleware.js";

window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: (to) => {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: "smooth",
            };
        } else {
            return {top: 0};
        }
    },
});

router.beforeEach(authMiddleware)
router.beforeEach(sawTutorialMiddleware)
router.beforeEach((to, from) => {
    const IsItABackButton = window.popStateDetected
    window.popStateDetected = false
    return !(IsItABackButton && from.name === 'schedule');
})

export default router;
