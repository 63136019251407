import {AuthService} from "~/core/services/AuthService.js";
import {useAuthenticationStore} from "~/stores/authenticationStore.js";
import * as Sentry from "@sentry/vue"

export const sawTutorialMiddleware = async (to, from, next) => {
    if (
        (to.meta.hasOwnProperty('public') && to.meta.public) ||
        to.name === 'tutorial'
    ) {
        next()
    } else {
        const sawTutorial = localStorage.getItem('sawTutorial') === 'true' || false
        if (!sawTutorial) {
            next({name: 'tutorial'})
        } else {
            next()
        }
    }
}